import React from "react"
import Page from "./../../templates/page.js"
import { Helmet } from 'react-helmet'
import ContactForm from "./../../components/form.js"

export default () => {

    const main = <>
        <h2>Frequently Asked Questions</h2>

        <p><b>What are the payment methods?</b><br />During the checkout process you can pay with credit card, PayPal, iDEAL, Apple Pay, SOFORT, Bancontact and Giropay.</p>

        <p><b>What is the lead time of my order?</b><br />Each product is built to order. Since I have a full time job this will take some time. That being
        said, I am committed to ship out within two to three weeks. And of course, if you are in luck it can be sooner.</p>
        
        <p><b>What is the shipping time?</b><br />Packages will ship with PostNL registered mail which takes 3-5 days for most of Europe.
        For packages outside of Europe shipping times will differ. Indicated shipping to the United States is 4-8 business days.</p>

        <p><b>How much is the shipping cost?</b><br />The shipping cost are calculated during the checkout process depending on your country of residence.</p>

        <p><b>Can I follow my package?</b><br />You will receive a tracking code as soon as the product has shipped. 
        I do not offer shipping options without tracking in order to prevent any ambiguity that might arise from that.</p>

        <p><b>Can I return my item?</b><br />Since each product is built to order, returns are not possible. If the product has an issue or does not meet
        your expectations, please reach out to me and we will figure it out together.</p>

        <p><b>How do I install or set up my product?</b><br />Instructions will be included with your order where needed and linked on the product page. If anything remains unclear send
        me a message with your questions.</p>

        <p><b>Do you offer any form of warranty?</b><br />If you have any issues or problems with a product you bought, send me a message using the form
        on this page to explain the issue and I will look at it on a case by case basis.</p>

        <p><b>What if something goes wrong?</b><br />All products are developed and tested to the best intentions, but being a private seller I do not have the resources that a
        large company can put in bringing a product to market. As a consequence, all products are sold as if being prototypes, and all usage is fully at your own risk. I do not accept any liability, obligation or responsibility 
        whatsoever for any loss, destruction or damage caused by using the product.</p>
    </>;

    const side = <div className="about"><h2>Contact</h2>

        <p>If you have any other questions, shoot me a message!</p>

        <ContactForm />

    </div>;

    return (
        <>
            <Helmet>
                <title>Contact - Maakbaas</title>
                <meta name="description" content="I am an engineer by day, maker by night, who likes reinventing the wheel. If you have any comments on my projects, I'd be happy to get in touch with you." />
            </Helmet>
            <Page title={'Shop FAQ'} content={main} sidebar={side}
                  />
        </>);

}